(function (onlineAt, $) {
    "use strict";

    var initTooltips = function() {
        // Initialize tooltips and popovers
        $('[data-toggle="tooltip"]', this).tooltip();
        $('[data-toggle="popover"]', this).popover();
    };

    var initSpinners = function() {
        // Spinners (http://spin.js.org/#jquery)
        $('.spinner-tiny', this).spin('tiny');
        $('.spinner-small', this).spin('small');
        $('.spinner-large', this).spin('large');
    };

    var initFolding = function() {
        $('.foldud', this).click(function() {
            $(this).parent().parent().next('.hiddenblock').css('display', 'block');
            $(this).css('display', 'none');
            $(this).next('.foldsammen').css('display', 'block');

            $("#arb-probs-1 ul .arb-prob-row").each(function(item) {
                var offset = $(".arb-prob-frist", this).width();
                $(".arb-prob-title", this).css("max-width", "calc(100% - " + (parseInt(offset, 10) + 60) + "px) ");
            });
        });

        $('.foldsammen', this).click(function() {
            $(this).parent().parent().next('.hiddenblock').css('display', 'none');
            $(this).css('display', 'none');
            $(this).prev('.foldud').css('display', 'block');
        });
    };

    var initNavigation = function() {
        //  Aktiverer mobil menu
        $('#navigation_menu', this).dlmenu();
    };

    // TODO: This could easily be done on the serverside
    var fixBreadcrumbs = function() {
        var lastli = $('ol.breadcrumb li').last();
        $(lastli).addClass('active');
        var text = $(lastli).find('a').text();
        $(lastli).html('');
        $(lastli).html(text);
    };

    onlineAt.registerPostLoad(initTooltips);
    onlineAt.registerAsyncLoaded(initTooltips);

    onlineAt.registerPostLoad(initSpinners);
    onlineAt.registerAsyncLoaded(initSpinners);

    onlineAt.registerPostLoad(initFolding);
    onlineAt.registerAsyncLoaded(initFolding);

    onlineAt.registerPostLoad(initNavigation);
    onlineAt.registerPostLoad(fixBreadcrumbs);
    
}(onlineAt, jQuery));