(function (onlineAt, $) {

    // Add the default OnlineAt datatables options
    onlineAt.overrideDataTablesOptions({
        "paging": true,
        "info": true,
        "searching": true,
        "dom": '<"row"<"col-xs-4"l><"col-xs-4 text-center"i><"col-xs-4"f>>rtp',
        "stateSave": true,
        "language": {
            "search": "_INPUT_",
            "searchPlaceholder": "S\u00f8g i tabel",
            "processing": "Henter...",
            "lengthMenu": "Vis _MENU_ r&aelig;kker af gangen",
            "zeroRecords": "Ingen r&aelig;kker matcher s&oslash;gningen",
            "info": "Viser _START_ til _END_ af _TOTAL_ r&aelig;kker",
            "infoEmpty": "Viser 0 til 0 af 0 r&aelig;kker ",
            "infoFiltered": "(filtreret fra _MAX_ r&aelig;kker)",
            "ifoPostFix": "",
            "url": "",
            "paginate": {
                "first": "F&oslash;rste",
                "previous": "Forrige",
                "next": "N&aelig;ste",
                "last": "Sidste"
            }
        },
        "initComplete": function (settings, json) {

            $(settings.nTableWrapper).find('.dataTables_filter label').append('<span class="searchclearbutton glyphicon glyphicon-remove-circle"></span>');

            var inputElm = $(settings.nTableWrapper).find('.dataTables_filter input.input-sm');
            var button = $(settings.nTableWrapper).find('.dataTables_filter .searchclearbutton');;
            var tableWrapper = $(settings.nTableWrapper);
            var table = $(settings.nTable);
            var defaultSortingString = table.attr("data-order");

            function isDefaultSortOrder() {
                var sortOrder = table.dataTable().fnSettings().aaSorting;
                var defaultSorting = JSON.parse(defaultSortingString);
                if (sortOrder.length !== defaultSorting.length)
                    return false;

                var is_equal = true;
                for (var i = 0; i < sortOrder.length && is_equal; i++)
                    is_equal = sortOrder[i][0] === defaultSorting[i][0] && sortOrder[i][1] === defaultSorting[i][1];


                return is_equal;
            }

            function isDefaultGroupValue() {
                return ($('#gruppeFilter').length === 0 || $('#gruppeFilter').val() === "-1");
            }

            function isDefaultSmiley() {
                return window.location.search.indexOf('smileyFilter') === -1;
            }

            function toggleButton() { 
                var bool = inputElm.val().length > 0 ||
                           !isDefaultSortOrder() ||
                           !isDefaultGroupValue() ||
                           !isDefaultSmiley()
                            ;


                if (bool) button.addClass('enabled');
                else button.removeClass('enabled');
            }

            function togglePlaceholder() {
                var bool = inputElm.val().length > 0;

                if (bool) inputElm.addClass('placeholder-not-shown');
                else inputElm.removeClass('placeholder-not-shown');
            }
            
            // Initialize button/placeholde
            inputElm.each(function () {
                toggleButton();
                togglePlaceholder();
            });

            // Setup eventhandlers
            tableWrapper.find('.dataTables_filter input.input-sm')
                .on('blur', function () {
                togglePlaceholder();
                }).on('input', function() {
                toggleButton();
            });

            // Funktionalitet til clearbutton
            button.on('click', function (e) {
                // Stop knap fra at få focus.
                e.stopImmediatePropagation();
                e.preventDefault();

                table.DataTable().state.clear();
                table.DataTable().destroy();
                table.dataTable(onlineAt.dataTableOptions);

                var defaultSorting = JSON.parse(defaultSortingString);
                $(settings.nTable).dataTable().fnSort(defaultSorting); // Resets column order

                // Er der et gruppe filter der skal resettes?
                if ($('#gruppeFilter').length > 0)
                    $('#gruppeFilter').val(-1).trigger('change');

                // Er der et smiley filter der kan resettes?
                if ($('ul.dropdown-menu li:first-child a').length > 0)
                    $('ul.dropdown-menu li:first-child a').trigger('click');

            });

            table.find("thead tr").find("th.sorting_asc, th.sorting, th.sorting_desc").on('click', function() {
                toggleButton();
            });

            $('#gruppeFilter').on('change', function () {
                toggleButton();
            });

            $("#SmileyFilter ul li a").on('click', function() {
                toggleButton();
            });
        },
        "drawCallback": function (oSettings) {
            if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
                $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
            } else {
                $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
            }
        }
    });


    var initDataTables = function () {
        // Register to activate all data-tables found in async postback
        onlineAt.dataTables = $('.data-table', this).dataTable(onlineAt.dataTableOptions);
    };

    onlineAt.registerPostLoad(initDataTables);
    onlineAt.registerAsyncLoaded(initDataTables);
}(onlineAt, jQuery));