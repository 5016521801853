var onlineAt = (function ($, Sys) {
    "use strict";
    var olat = {},
        postAsyncLoadingEvents = [],
        postAsyncLoadedEvents = [],
        postLoadEvents = [],
        dataTableOptions = {},
        dataTables = undefined;

    var postContentLoad = function(isAsync, context) {
        if (!isAsync) {
            for (var j = 0; j < postLoadEvents.length; j++) {
                postLoadEvents[j].apply(context);
            }
        }

        if (isAsync) {
            for (var j = 0; j < postAsyncLoadingEvents.length; j++) {
                postAsyncLoadingEvents[j].apply(context);
            }

            for (var j = 0; j < postAsyncLoadedEvents.length; j++) {
                postAsyncLoadedEvents[j].apply(context);
            }
        }
    };

    // Registeres general handling of async load completed event.
    Sys.WebForms.PageRequestManager.getInstance().add_endRequest(function (sender, args) {
        if (args.get_error() != undefined) {
            if (!args.get_errorHandled()) {
                $('#ajax-error-message-modal').modal({
                    backdrop: false,
                    keyboard: true
                });
            }
            console.error('Backend returned an error in AJAX request: ' + args.get_error().message);
            args.set_errorHandled(true);
        } else {
            var updatePanelSelectorIds = $.map(sender._updatePanelClientIDs, function (elmId) { return '#' + elmId; }).join(", ");
            postContentLoad(true, $(updatePanelSelectorIds));
        }
    });

    // Registeres for ready event and trigger postContentLoad
    $(function () {
        postContentLoad(false, $('body'));

        // Trigger any async data loading
        $('.async-load-trigger').click();
    });    

    olat.registerPostLoad = function (fn) {
        postLoadEvents.push(fn);
    }

    olat.registerAsyncLoading = function(fn) {
        postAsyncLoadingEvents.push(fn);
    }

    olat.registerAsyncLoaded = function (fn) {
        postAsyncLoadedEvents.push(fn);
    }

    olat.overrideDataTablesOptions = function (options) {
        $.extend(dataTableOptions, options);
    }

    olat.dataTableOptions = dataTableOptions;
    olat.dataTables = dataTables;
    
    return olat;
}(jQuery, Sys));
